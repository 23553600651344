$bgcolor: #545b62;
$blackcolor: #000;
$whitecolor:#fff;
$themecolor: #00348d;
$disabledcolor: #666;
$fontsize: 1.125rem;
$headingFontSize: 1.5rem;
@import "register";
@import "resetpassword";
@import "profile";
@import "earnpoint";
@import "validatepurchase";
@import "rewards";
@import "tierinfo";
// @import "variables";
// @import "colors";
// @import "reset";

//Body style
body{
	font-size:$fontsize; 
	line-height: 1.4rem;
}

// START HEARDER //
// Main menu //
.navbar{    
	padding-top:0;
	padding-bottom: 0;
	background: $whitecolor;
	box-shadow: 0px 3px 15px 0px rgba(54, 54, 54, 0.6);
	-webkit-box-shadow: 0px 3px 15px 0px rgba(54, 54, 54, 0.6);
	-moz-box-shadow: 0px 3px 15px 0px rgba(54, 54, 54, 0.6);
}
.navbar-nav .nav-item .nav-link{
	padding:10px 15px;
}
.navbar-light .navbar-nav .nav-link:focus, 
.navbar-light .navbar-nav .nav-link:hover,
.dd-logout-button-top-right{
	color: $blackcolor;
}
.navbar-nav .nav-item .nav-link,
.dd-logout-button-top-right{
	color:$themecolor; 
	font-size: 1rem;
}
button.user-menu-icon{
	background: none;
	padding: 0;
}
ul.navbar-nav li a,
ul.dropdown-menu li a{
	text-transform: uppercase;
}
.dropdown-menu{
	padding: 0;
	min-width: 13rem;
}

ul.dropdown-menu li:first-child .nav-link{
	padding-top: 20px;
}
ul.dropdown-menu li:last-child .nav-link{
padding-bottom: 20px;
}
.dd-logout-button-top-right:hover,
ul.dropdown-menu li a:hover{background-color: #fafafa;}
.dropdown-toggle::after{
	margin-left: .55em;
}
.dd-logout-button-top-right{
	background: none;
	border:none; 
	outline: none;
	width: 100%; 
	text-align: left;
}


// END MAIN MENU //

// SKIP CONTENT //
.skiptocontent{position: absolute;
	opacity: 1;
	left: 0%;
	top:-60px;
	padding:3px 10px;
	z-index: 9999;
	background: $bgcolor; 
	color: $whitecolor;
}
.skiptocontent:focus{opacity: 1;
	top:0px;
	background: $bgcolor; 
	color: $whitecolor;
}
// END SKIP CONTENT //
// END HEARDER //

// COMMON ELEMENTS //
// HEADING //
h1{font-size: 2rem; text-transform: uppercase;}
h2{font-size: 1.5rem;text-transform: uppercase;}
h3{font-size: 1.125rem; font-weight: bold;}
.fs-2{font-size: 2rem;}
.main-heading {
	text-align: center;
	margin: 20px 0px 50px 0px;
}
// ENd HEADING //

// BUTTON and LINK //
a {
	color: $blackcolor;
}
.btn-primary{
	color: $whitecolor;
	font-size: $fontsize;
	text-transform: Uppercase;
	background-color: $themecolor;
	border-color: $themecolor;
	min-width: 160px;
	letter-spacing: 0.5px;
	font-weight: 600;
}
// END BUTTON and LINK //

// PAGE SECTION //
.section{
	margin-top: 3rem;
	margin-bottom: 3rem;
}
// END PAGE SECTION //
.page {
	display: flex;
	justify-content: center;
	margin: 50px 0px;

	.card {
		width: 100%;
		padding: 20px 40px 60px 40px;
	}
}
.card {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
	transition: 0.3s;
	margin-bottom: 20px;

	&:hover {
		box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);

		.cart-button {
			color: $whitecolor;
			background-color: $themecolor;
			border-color: $themecolor;
		}
	}

	.cart-button {
		color: $themecolor;
		background-color: $whitecolor;
		border-color: $themecolor;
		margin: 10px 20px;
	}
}

// FROM CONTROLS //
button,
input{outline: 0;}
.col-form-label {
	font-weight: 700;
	font-size: 1.125rem;
	line-height: 24px;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.form-group .form-control {
	background: $whitecolor;
	color: $blackcolor;
	border: none;
	border-bottom: 4px solid $blackcolor;
	height: 36px;
	border-radius: 0;
	font-size: 1.125rem;
	padding: 5px 15px 5px 0;
	outline: 0;
}


// END COMMON ELEMENTS //

// PAGES //

// HOME //

// LOGIN //
.forgot-pass-links {
	margin: 10px 0px;
	text-align: right;
}
// REGISTER //

// FAQs //
.page {
	.faq{
		.card{
			padding: 0;
		}
		.accordion>.card>.card-header,
		.accordion .card-header {
			background-color: $blackcolor;
			color: $whitecolor;
			font-size: 1.125rem;
			line-height: 32px;
		}

		.accordion .card-body {
			padding: 20px 30px;
			line-height: 34px;

			li {
				padding: 5px 0;
			}
		}
	}
}

.faq_open-close-icon {
	color: $whitecolor;
	line-height: 2rem;
	font-size: 2rem;
	font-family: "Arial", "Helvetica Neue" ;
}
// END FAQS //


// AFTER LOGIN PAGES //
.post-login {

	justify-content: center;
	margin: 50px 0px;
}

/* .header-parent-label */
.post-login {
	h2 {
	font-weight: 600;
	font-size: 1.5rem;
	width: 100%;
	text-align: center;
	margin: 30px 0px;
	padding-bottom: 10px;
	border-bottom: 2px solid #2d2a2a;
	}

	.card {
		margin-bottom: 50px;
		
		.activity-block {
			cursor: pointer;
			
		}
	}
	.card_holder{
		.col{
			display: flex;
		}
		.card{
			width: 100%;
		}
	}
}


.activity-block {
	cursor: pointer;
	height: -webkit-fill-available;

	&:hover {
		background-color: rgb(237, 241, 245);
		color: #000;
	}
	.activity-img-holder{
		height: 200px;
		overflow:hidden;
		align-items: center;
		justify-items: center;
		justify-content: center;
		margin: 0 auto;

		img {
				width: 100%;
			}
	}
	.activity-info {
		margin: 30px 15px;
		font-size: 1.3rem;

		.points {
			margin-bottom: 15px;
			font-weight: bold;
		}

		h5 {
			font-weight: normal;
		}

	}
}
.activity-block-disable{
	cursor: default !important;
	background-color: #f1efefe0;
	
	.cart-button {
			color: $disabledcolor;
			background-color: $whitecolor;
			border-color: $disabledcolor;
		}

	&:hover{
		
		background-color: #f1efefe0;

	.cart-button {
			color: $disabledcolor;
			background-color: $whitecolor;
			border-color: $disabledcolor;
		}
	}
}

// SUBMIT CODE //
.addcode {
	background-color: #eeeeee;
	font-size: $fontsize;
	border:1px solid $bgcolor;
	border-radius: 4px;
	padding: 0px;
	margin-bottom: 20px;
	.form-group{
		margin-bottom: 0;
	}
	label{
		text-transform: capitalize;
		line-height: 40px;
		padding: 0 15px;
		margin-bottom: 0;
		
	}
	.form-control{
		border-bottom: none;
		width: calc(100% - 172px);
		line-height: 40px;
		height: 40px;
		float: right;
	}
	.btn-primary{
		height: 40px;
		min-width: 40px;
		display: inline-block;
		float: right;
		border-radius: 0 4px 4px 0;
	}
}

// END SUBMIT CODE //






// TABLE  //
table thead {
	background-color: $themecolor;
	color: $whitecolor;
	font-weight: normal;
}
.table-bordered td {
	border: none;
	border-bottom: 1px solid #eeeeee;
}
.table-bordered tbody tr:first-child td {
	padding-top: 30px;
}
.table-bordered tbody tr:last-child td {
	padding-bottom: 30px;
}
// END TABLE //

// END AFTER LOGIN PAGES //


.submit button {
	color: $whitecolor;
	background-color: #545b62;
	border-color: #545b62;
	margin: 10px 20px;
}
/* portal point list */
// .portal-point-list {
// 	display: flex;
// 	flex-wrap: wrap;
// 	justify-content: space-between;
// 	margin: 10px 0px;
// 	.title {
// 		margin: 10px 30px 0 30px;
// 	}
// 	.points-actions {
// 		margin: 10px 30px 0 30px;
// 	}
// }

.social-login {
	display: flex;
	justify-content: center;
	& > div {
		margin: 10px;
	}
}

/* START Footer */
footer{background-color: $bgcolor; color: $whitecolor; padding-top:1.5rem; padding-bottom: .5rem;}
footer a{color: $whitecolor;}
footer a:hover{color: $whitecolor;}
.footer-container {
	display: flex;
	justify-content: center;
}
.social-links {
	display: flex;
	justify-content: center;
}
.footer-social {
	list-style: none;
	display: inline;
	padding: 0px;
	li {
		float: left;
		padding: 1rem;
	}
}
.footer-link {
	display: flex;
	justify-content: center;
	ul {
		list-style: none;
		display: inline;
	}
	li {
		float: left;
		padding: 0 1rem;
	}
}

.copy-text {
	margin: 20px 0px 0px;
	text-align: center;
	p {
		display: inline;
	}
}
.tierlogic {
	margin: 1rem 0px 8px;
	text-align: center;
}

/* END Footer */

.input-error {
	color: #d60000;
	font-size: 1.125rem;
}
.asterisk:after {
	content: "*";
	width: 5px;
	height: 5px;
	color: red;
	font-weight: bold;
}

.loaderHolder {
	left: 0;
	top: 0;
	position: fixed;
	background: transparent;
	width: 100%;
	height: 100%;
	background-color: rgba(126, 125, 111, 0.23);
	z-index: 1100;
}
.loaderMain {
	position: absolute;
	left: calc(50% - 50px);
	top: 40%;
	z-index: 1000;
	height: 31px;
	width: 31px;
}

.kep-login-facebook.metro {
	font-size: 13px;
	width: 200px;
	padding: 10px 0px;
}
#confirm-account-h1 {
	border: 1px solid #ccc;
	padding: 40px 40px;
	color: #4ab116;
	text-align: center;
}
#confirm-account-h1 {
	border: 1px solid #ccc;
	padding: 40px 40px;
	color: #4ab116;
	text-align: center;
}
.react-calender {
	position: absolute;
}

// Medium devices (tablets, less than 992px)
@media (min-width: 991.98px) {
	// .top-navigation {
	// 	justify-content: center;
	// }
}
.menu-notification-icon {
	color: rgb(157, 160, 11);
	text-decoration: none;
	position: relative;
	display: inline-block;
	border-radius: 2px;
}

.menu-notification-icon:hover {
	color: red;
}

.menu-notification-icon .badge {
	position: absolute;
	top: -10px;
	right: -10px;
	padding: 5px 8px;
	border-radius: 50%;
	background: red;
	color: $whitecolor;
}
.notification-sidepanel {
	width: 35%;
	position: fixed;
	z-index: 77;
	min-height: 80%;
	max-height: 80%;
	top: 100px;
	right: 0;
	background-color: #eeebeb;
	overflow-x: hidden;
	transition: 0.5s;
	padding-top: 20px;
}
.notification-sidepanel .closebtn {
	float: right;
}
.not-read-notificaion {
	color: #d60000;
}
.otp-input {
	width: 40px;
	height: 40px;
}


@media (max-width: 640px) {
	.post-login .card{
		margin-bottom: 20px;
	}
	.activity-block{
		position: relative;

		.activity-img-holder {
			max-height: 115px;
			width: 37%;
			overflow: hidden;
			align-items: inherit;
			justify-items: center;
			justify-content: left;
			margin: 1px 15px 0 1px;
			display: inline-block;
			border-radius: 4px;

		}

		.activity-info {
			margin: 15px 0;
			font-size: 1.3rem;
			display: inline-block;
			width: calc(100% - 43%);
			position: absolute;

			.points{
				margin-bottom: 10px;
			}
		}
	}

}
